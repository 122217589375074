import React from 'react';
import { helpers, types } from 'helpers';
import { Form, Select, Tabs } from 'antd';
import { useTypedSelector } from 'Store/_Store';


const SettingsConfigProviders = ({ currencies }: { currencies: types.Currencies[] }) => {

    const { _utils: { config }, } = useTypedSelector((state) => state);
    
    let tabs: string[] = currencies;
    if (window.location.pathname.includes('/settings')) {
        tabs = [...tabs];
    }

    const Card = (props: { currency: string, type: string, network: string }) => {
        const { currency, type, network } = props;
        let channel = types.TrxChannels.MOMO;
        if (network === types.TrxChannels.BANK || network === types.TrxChannels.CARD) {
            channel = network;
        }
        return (
            <div
                key={network}
                className={`
                    p-4 border rounded-lg 
                    ${type === 'DEBIT' && 'bg-green-50'} 
                    ${type === 'CREDIT' && 'bg-red-50'}
                    ${((type === types.TrxTypes.DEBIT && network === 'BANK' && currency === types.Currencies.GHS) || (type === types.TrxTypes.CREDIT && network === 'CARD')) && 'hidden'}
                `}
            >
                <Form.Item
                    label={`${type} - ${network}`}
                    name={['providers', currency, network, type, channel.toLowerCase()]}
                    className="mb-2"
                >
                    <Select mode="tags">
                        {(config.networks[currency as types.Currencies][network][type] || []).map((option: string, i: number) => (
                            <Select.Option key={`${option}_${i}`} value={option}>
                                {helpers.ucFirst(option)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="p-4s">
                <Tabs
                    defaultActiveKey={currencies[0]}
                    type="card"
                    tabPosition={window.location.pathname.includes('merchants') ? 'top' : 'left'}
                    items={
                        tabs.map((currency) => {
                            return {
                                key: currency,
                                label: <span>&nbsp;&nbsp;{currency}&nbsp;&nbsp;</span>,
                                children: (
                                    <div key={currency} className="grid grid-cols-1 gap-6">
                                        {[types.TrxTypes.DEBIT, types.TrxTypes.CREDIT].map(type => (
                                            <div key={type} className={`grid grid-cols-3 gap-6 ${type === types.TrxTypes.DEBIT ? 'border-b pb-6' : ''}`}>
                                                {Object.keys(config.networks[currency as types.Currencies] || []).map((network: string) => (
                                                    <Card key={network} type={type} network={network} currency={currency} />
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ),
                            };
                        })
                    }
                />

                <div className="grids grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6 hidden">
                    {currencies.map(currency => (
                        <div key={currency} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsConfigProviders;