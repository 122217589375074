import React from 'react';
import { types } from 'helpers';
import { Form, Input, Tabs } from 'antd';
import { useTypedSelector } from 'Store/_Store';


const SettingsConfigFees = ({ loading, currencies }: { loading: boolean; currencies: types.Currencies[] }) => {

    const { _utils: { config }, } = useTypedSelector((state) => state);

    React.useEffect(() => {
        // console.log('fees');
    }, []);

    const Card = (props: { currency: string, type: string, network: string }) => {
        const { currency, type, network } = props;
        return (
            <div
                key={network}
                className={`
                    border rounded-lg 
                    ${type === types.TrxTypes.DEBIT && 'bg-green-50'} 
                    ${type === 'CREDIT' && 'bg-red-50'}
                    ${((type === types.TrxTypes.DEBIT && network === 'BANK' && currency === types.Currencies.GHS) || (type === types.TrxTypes.CREDIT && network === 'CARD')) && 'hidden'}
                `}
            >
                <div className={`${type === types.TrxTypes.DEBIT && 'bg-green-200'} ${type === 'CREDIT' && 'bg-red-200'} px-4 py-2 font-semibold rounded-tl-lg rounded-tr-lg`}>{type} - {network}</div>
                <div className="p-4">
                    {((types.feesAddOrPercentage[currency] || {})[type] || []).includes('percentage') && (
                        <Form.Item label={`Percentage`} name={['fees', currency, network, type, 'percentage']} className="mb-2">
                            <Input addonAfter="%" type="number" step="0.1" size="large" autoComplete="off" disabled={loading} />
                        </Form.Item>
                    )}
                    {((types.feesAddOrPercentage[currency] || {})[type] || []).includes('add') && (
                        <Form.Item label={`Add amount`} name={['fees', currency, network, type, 'add']} className="mb-2">
                            <Input addonBefore={`+`} type="number" step="0.1" size="large" autoComplete="off" disabled={loading} />
                        </Form.Item>
                    )}
                    <Form.Item label={`Max amount`} name={['fees', currency, network, type, 'max']} className="mb-2">
                        <Input type="number" step="0.1" size="large" autoComplete="off" disabled={loading} />
                    </Form.Item>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="p-4s">
                <Tabs
                    defaultActiveKey={currencies[0]}
                    type="card"
                    tabPosition={window.location.pathname.includes('merchants') ? 'top' : 'left'}
                    items={
                        currencies.map(currency => {
                            return {
                                key: currency,
                                label: <span>&nbsp;&nbsp;{currency}&nbsp;&nbsp;</span>,
                                children: (
                                    <div key={currency} className="grid grid-cols-1 gap-6">
                                        <div key={currency} className="grid grid-cols-1 gap-6">
                                            {[types.TrxTypes.DEBIT, types.TrxTypes.CREDIT].map(type => (
                                                <div key={type}>
                                                    {((types.feesAddOrPercentage[currency] || {})[type] || []).length > 0 && (
                                                        <div className={`grid grid-cols-3 gap-6 ${type === types.TrxTypes.DEBIT ? 'border-b pb-6' : ''}`}>
                                                            {Object.keys(config.networks[currency as types.Currencies] || {}).map((network: string) => (
                                                                <Card key={network} type={type} network={network} currency={currency} />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-6 lg:col-span-4 xl:col-span-3">
                                                <Form.Item label={`Settlement add amount`} name={['fees', currency, 'SETTLEMENT', 'add']} className="mb-2">
                                                    <Input addonBefore={`+${currency}`} type="number" step="0.1" size="large" autoComplete="off" disabled={loading} />
                                                </Form.Item>
                                            </div>
                                            {currency === types.Currencies.GHS && (
                                                <div className="col-span-6 lg:col-span-4 xl:col-span-3">
                                                    <Form.Item label={`SMS fee`} name={['fees', currency, 'SMS']} className="mb-2">
                                                        <Input addonBefore={currency} type="number" step="0.01" size="large" autoComplete="off" disabled={loading} />
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ),
                            };
                        })
                    }
                />
                <div className="grids grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6 hidden">
                    {currencies.map(currency => (
                        <div key={currency} className="p-4 bg-teal-50 border rounded-lg" />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsConfigFees;