import React from 'react';
import { Avatar, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { axius, helpers, images, } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { useDispatch } from 'react-redux';
import { useLazyMeQuery } from 'Store/api/auth.api';
import { _setUtils } from 'Store/slices/utils.slice';
import { useLazyConfigQuery } from 'Store/api/utils.api';

const Header = React.lazy(() => import('./Header'));

const Leyout = ({ children }: { children: any }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { _auth: { user, token }, _utils: { meCache, businessCache }, _utils } = useTypedSelector((state) => state);

    const [getConfig] = useLazyConfigQuery();
    const [getMyDetails] = useLazyMeQuery();

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isMobileMenuVisible = helpers.isMobile ? _utils.isMobileMenuVisible : true;

    React.useEffect(() => {
        if (token) {
            getConfig({});
            getMyDetails({ id: user.id, cache: meCache });
        }
        // eslint-disable-next-line
    }, [token, meCache, businessCache]);

    const items = [
        { icon: images.NavOverview, label: `Overview`, path: '/', },
        { icon: images.NavCustomers, label: `Merchants`, path: '/merchants', role: 'CUSTOMERS', },
        { icon: images.NavCustomers, label: `Compliance`, path: '/compliance', role: 'COMPLIANCE', },
        { icon: images.NavStatement, label: `Statement`, path: '/statement', role: 'STATEMENT', },
        { icon: images.NavSettlements, label: `Settlements`, path: '/settlements', role: 'SETTLEMENTS', },

        { icon: images.NavTeam, label: `Team`, path: '/team', role: 'TEAM', },
        { icon: images.NavReports, label: `Reports`, path: '/reports', role: 'LOGS', },
        { icon: images.NavSettings, label: `Settings`, path: '/settings', },
    ].filter(item => !item.role || helpers.hasPermission(item.role));

    const current = '/' + (location.pathname.split('/')[1] || '');
    const headerTitle = items.find((item: any) => item.path === current)?.label || '';

    const logout = () => {
        mobileMenu(false);
        axius.signOutSuccess();
    }

    const mobileMenu = (value: boolean) => {
        dispatch(_setUtils({ key: 'isMobileMenuVisible', value }));
    }

    return (
        <React.Fragment>
            {isLoggedIn ? (
                <>
                    <Layout hasSider>
                        {isMobileMenuVisible && (
                            <div className="sider-overlay">
                                <div className="flex justify-end items-center px-5 pt-7">
                                    <img
                                        src={images.CloseWhite}
                                        alt="Close mobile menu"
                                        onClick={() => mobileMenu(!isMobileMenuVisible)}
                                    />
                                </div>
                            </div>
                        )}
                        <Layout.Sider
                            style={{
                                overflow: 'auto',
                                height: '100vh',
                                position: 'fixed',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                display: isMobileMenuVisible ? 'block' : 'none',
                            }}
                        >
                            <div className="px-5 pt-6 mb-6 md:pt-10 md:mb-8 lg:pt-10 lg:mb-8">
                                <img src={images.Logo} alt="Open Gateway logo" />
                            </div>
                            <div className="border-b border-gray-600 mb-6" />
                            <div className="px-5 flex flex-col justify-between" style={{ height: `87.5vh` }}>
                                <div>
                                    {items.map(item => (
                                        item.path === 'break' ? (
                                            <div key={item.path} className="my-4 mx-4 border-b border-gray-600" />
                                        ) : (
                                            item.path.includes('http') ? (
                                                <a
                                                    href={item.path}
                                                    key={item.path}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out pop`}
                                                >
                                                    {item.label}
                                                </a>
                                            ) : (
                                                <Link
                                                    to={item.path}
                                                    key={item.path}
                                                    className={`flex justify-start gap-2 py-3 px-4 cursor-pointer nav-item transition ease-in-out pop ${current === item.path ? 'active' : ''}`}
                                                    onClick={() => mobileMenu(false)}
                                                >
                                                    <div><img src={item.icon as any} className="mr-2 h-[22px]" alt={item.label} /></div>
                                                    <div>{item.label}</div>
                                                </Link>
                                            )
                                        )
                                    ))}
                                </div>
                                <div>
                                    <div className="flex items-center py-3 px-4 text-white">
                                        <div className="w-[32px] h-[32px]">
                                            <Avatar shape="circle" style={{ backgroundColor: '#027a7a' }}>
                                                {user.first_name[0]}{user.last_name[0]}
                                            </Avatar>
                                        </div>
                                        <div className="ml-2">
                                            <div className="font-semibold">{user.first_name} {user.last_name}</div>
                                            <div className="text-sm text-gray-400">{user.email}</div>
                                        </div>
                                    </div>
                                    <div className="my-2 border-b border-gray-600" />
                                    <div className="text-white">
                                        <span
                                            className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out mx-5s pop`}
                                            onClick={logout}
                                        >
                                            <div><img src={images.Logout} className="mr-2" alt="Logout" /></div>
                                            <div>Logout</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Layout.Sider>
                        <Layout className="site-layout">
                            <div className="container">
                                <Header title={headerTitle} />
                                <Layout.Content style={{ overflow: 'initial' }}>
                                    <div className="p-5">
                                        {children}
                                    </div>
                                </Layout.Content>
                            </div>
                        </Layout>
                    </Layout>
                </>
            ) : (
                <div>{children}</div>
            )}
        </React.Fragment>
    );
}

export default Leyout;