export interface AxiusResponse {
    data: any;
    status: number;
    message: string;
}

export interface ApiTrigger {
    data: any;
    error: any;
    message: string;
}

export interface StoreInterface {
    _store: {
        _auth: {
            user: User;
            token: string;
        },
        _utils: {
            env: 'test' | 'live';
            limit: number;
            loading: {
                primary: string;
                spinning: boolean;
                secondary: string;
            }
        },
        _data: {
            key: {
                id: string;
                test_key: string;
                live_key: string;
            };
            business: Business;
        }
    },
    _setAuth: (value: { user: User, token: string }) => void;
    _setUser: (value: User) => void;
    _setToken: (value: string) => void;
    _setData: (payload: { key: 'business', value: any }) => void;
    _setUtils: (payload: { key: 'loading' | 'env', value: any }) => void;
}
export interface User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    password: string;
    created_at: string;
    updated_at: string;
    teams: Team[];
    type: UserTypes;
    country?: string;
    status?: TeamStatus;
    admin_role_id?: string;
    admin_role?: AdminRoles;
}

export enum UserTypes {
    USER = 'USER',
    ADMIN = 'ADMIN',
}

export interface AdminRoles {
    id: string;
    name: string;
    business: string[];
    permissions: string[];
    created_at: string;
    updated_at: string;
}

export interface Team {
    id: string;
    userId: string;
    businessId: string;
    business: Business;
    user: User;
}

export interface TeamInvite {
    id: string;
    name: string;
    email: string;
    status: TeamStatus;
    businessId: string;
    business: Business;
    created_at: string;
    updated_at: string;
}

export interface Business {
    id: string;
    name: string;
    industry: BusinessIndustries;
    description: string
    team_size: BusinessTeamSizes;
    balances: {
        test: number;
        live: number;
    };
    created_at: string;
    updated_at: string;
    callback_url: string;
    keys: Keys[];
    wallets: Wallet[];
    transactions: Transaction[];
    teams: Team[];
    preferences: {
        fees: Record<Currencies, any>;
        providers?: any;
        permissions?: string[];
    };
    status: TeamStatus;
    settlement_accounts?: SettlementAccount[];
    virtual_accounts?: VirtualAccount[];
    compliance: {
        address: {
            zip?: string;
            city: string;
            state: string;
            line1: string;
            line2?: string;
            proof: string;
            country: string;
            proof_link: string;
        },
        directors: {
            first_name: string;
            last_name: string;
            id_type: string;
            id_number: string;
            id_front: string;
            id_back: string;
        }[],
        shareholders: {
            type: 'Company' | 'Individual';
            first_name: string;
            last_name: string;
            id_type: string;
            id_number: string;
            id_front: string;
            id_back: string;
            registration_number: string;
            tax_number: string;
            incorporation_date: string;
            country: string;
        }[],
        documents: {
            doc_type: string;
            doc_file: string;
        }[],
        rejections: any;
    };
}

export interface BusinessFee {
    fee_debit_max?: number;
    fee_debit_add?: number;
    fee_debit_percentage?: number;
    fee_credit_max?: number;
    fee_credit_add?: number;
    fee_credit_percentage?: number;
    fee_card_percentage?: number;
}

export interface Wallet {
    id: string;
    currency: Currencies;
    businessId: string;
    balance: number;
    balance_test: number;
    balance_hold?: number;
    business?: Business;
}

export enum Currencies {
    GHS = 'GHS',
    NGN = 'NGN',
    UGX = 'UGX',
    XAF = 'XAF',
    XOF = 'XOF',
    KES = 'KES',
    TZS = 'TZS',
    ZMW = 'ZMW',
    GBP = 'GBP',
    EUR = 'EUR',
    USD = 'USD',
    USDT = 'USDT',
    USDC = 'USDC',
}

export const CurrenciesLabels = {
    [Currencies.GHS]: `Ghana Cedi`,
    [Currencies.NGN]: `Naira`,
    [Currencies.UGX]: `Ugandan Shilling`,
    [Currencies.KES]: `Kenyan Shilling`,
    [Currencies.TZS]: `Tanzanian Shilling`,
    [Currencies.ZMW]: `Kwacha`,
    [Currencies.XAF]: `Central African CFA franc`,
    [Currencies.XOF]: `West African CFA franc`,
    [Currencies.GBP]: `Pound`,
    [Currencies.EUR]: `Euro`,
    [Currencies.USD]: `US Dollar`,
    [Currencies.USDT]: `Tether`,
    [Currencies.USDC]: `USD Coin`,
}

export const CurrenciesSymbols = {
    [Currencies.GHS]: '₵',
    [Currencies.NGN]: '₦',
    [Currencies.UGX]: 'Ush',
    [Currencies.KES]: 'Ksh',
    [Currencies.TZS]: 'Tsh',
    [Currencies.ZMW]: 'ZK',
    [Currencies.XAF]: 'XAF',
    [Currencies.XOF]: 'XOF',
    [Currencies.EUR]: 'EUR',
    [Currencies.GBP]: 'GBP',
    [Currencies.USD]: 'USD',
    [Currencies.USDT]: 'USDT',
    [Currencies.USDC]: 'USDC',
}

export interface Transaction {
    id: string;
    amount: number;
    currency: string;
    status: TrxStatus;
    channel: TrxChannels;
    type: TrxTypes;
    customer: Customer;
    metadata: any;
    reason: string;
    fee: number;
    provider: TrxProviders;
    provider_init_response: any;
    provider_hook_response: any;
    businessId: string;
    callback_url: string;
    callback_data: any;
    created_at: string;
    updated_at: string;
    keysId: string;
    key: Keys;
    business: Business;
    reference: string;
    network: string;
    opening_balance: number;
    closing_balance: number;
    business_name: string;
    bear_fee: 'business' | 'customer';
    provider_message: string;
}

export interface Customer {
    accountName: string;
    accountNumber: string;
}

export interface Recipient {
    amount: number;
    bank?: string;
    network?: string;
    accountName: string;
    accountNumber: string;
}

export interface Keys {
    id: string;
    businessId: string;
    test_key: string;
    live_key: string;
    created_at: string;
    updated_at: string;
    business: Business;
    transactions: Transaction[];
}

export interface File {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}

export type UserBusiness = {
    user: User;
    business: Business;
};

export interface Filter {
    key: string;
    type: 'text' | 'number' | 'select' | 'date_range';
    value?: string;
    label?: string;
    options?: string[] | { label: string; value: string; }[];
    placeholder?: string;
}

export interface DataMeta {
    limit: number;
    next?: string;
    page: number;
    previous?: string;
    total: number;
}

export interface DebitMomo {
    amount: number;
    reason: string;
    network: string;
    currency: string;
    metadata: any;
    accountName: string;
    accountNumber: string;
    callbackURL?: string;
}

export interface CreditMomo {
    amount: number;
    reason: string;
    network: string;
    accountName: string;
    accountNumber: string;
}

export interface CreditBank {
    id?: string;
    amount: number;
    reason: string;
    bank: string;
    senderName: string;
    accountName: string;
    accountNumber: string;
}

export interface TrxOverview {
    totalAmount: number;
    totals?: any;
    performance: any;
    totalsCredit: any;
    totalsDebit: any;
}

export interface Logs {
    id: string;
    userId: string;
    action: string;
    details: string;
    created_at: string;
    updated_at: string;
    user: User;
}

export interface Volume {
    id: string;
    amount: number;
    currency: Currencies;
    provider: TrxProviders;
    stage: 'text' | 'live';
    date: string;
}


export enum TrxStatus {
    INITIATED = 'INITIATED',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
    OTP = 'OTP',
    CANCELLED = 'CANCELLED',
}

export enum TrxStatusLabels {
    INITIATED = 'Pending',
    FAILED = 'Failed',
    COMPLETED = 'Completed',
    OTP = 'OTP',
    CANCELLED = 'Cancelled',
}

export enum TrxChannels {
    MOMO = 'MOMO',
    BANK = 'BANK',
    CARD = 'CARD',
    CRYPTO = 'CRYPTO',
}

export enum TrxChannelsLabels {
    MOMO = 'Mobile Money',
    BANK = 'Bank',
    CARD = 'Card',
    CRYPTO = 'Crypto',
}

export enum TrxTypes {
    DEBIT = 'DEBIT',
    CREDIT = 'CREDIT',
    SETTLEMENT = 'SETTLEMENT',
}

export enum TrxProviders {
    DOSS = 'DOSS',
    MOOLRE = 'MOOLRE',
    LAZYPAY = 'LAZYPAY',
    PAYAZA = 'PAYAZA',
    EMERGENT = 'EMERGENT',
    PAYTOTA = 'PAYTOTA',
    MAGAMAONEPAY = 'MAGAMAONEPAY',
    BANI = 'BANI',
    HUB2 = 'HUB2',
    ZENITH = 'ZENITH',
    KORBA = 'KORBA',
    INTECH = 'INTECH',
    NEONPAY = 'NEONPAY',
}

export enum TrxNetworks {
    MTN = 'MTN',
    VOD = 'VOD',
    ATM = 'ATM',
}

export enum NetworksLabels {
    MTN = 'MTN Mobile Money',
    VOD = 'Telecel Cash',
    ATM = 'AirtelTigo Money',
}

export enum TrxNetworksLabels {
    MTN = 'MTN',
    VOD = 'VODAFONE',
    ATM = 'AIRTELTIGO',
}

export enum BusinessIndustries {
    Agriculture = 'Agriculture',
    Commerce = 'Commerce',
    Education = 'Education',
    Financial = 'Financial',
    Gaming = 'Gaming',
    Hospitality = 'Hospitality',
    Health = 'Health',
    Leisure = 'Leisure',
    Logistics = 'Logistics',
    Non_Profit = 'Non Profit',
    Travel = 'Travel',
    Utilities = 'Utilities',
}

export enum BusinessTeamSizes {
    SMALL = 'Small (1-50)',
    MEDIUM = 'Medium (50-150)',
    LARGE = 'Large (150-250)',
    EXTRA_LARGE = 'Enterprise (250+)',
}

export enum TeamStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export enum TeamStatusLabels {
    PENDING = 'Pending',
    ACCEPTED = 'Approved',
    REJECTED = 'Rejected',
}

export enum BusinessStatusLabels {
    PENDING = 'Pending',
    ACCEPTED = 'Approved',
    REJECTED = 'Rejected',
}

export enum Networks {
    MTN = 'MTN',
    VOD = 'VOD',
    ATM = 'ATM',
}

export enum Banks {
    'SCB' = 'STANDARD CHARTERED BANK',
    'BBG' = 'BARCLAYS BANK GHANA',
    'GCB' = 'GCB BANK LIMITED',
    'NIB' = 'NATIONAL INVESTMENT BANK',
    'ADB' = 'AGRICULTURAL DEVELOPMENT BANK',
    'UMB' = 'UNIVERSAL MERCHANT BANK',
    'REP' = 'REPUBLIC BANK LIMITED',
    'ZEN' = 'ZENITH BANK GHANA LTD',
    'ECO' = 'ECOBANK GHANA LTD',
    'CAL' = 'CAL BANK LIMITED',
    'FAB' = 'FIRST ATLANTIC BANK',
    'PRU' = 'PRUDENTIAL BANK LTD',
    'STA' = 'STANBIC BANK',
    'BOA' = 'BANK OF AFRICA',
    'GTB' = 'GUARANTY TRUST BANK',
    'FBL' = 'FIDELITY BANK LIMITED',
    'SSB' = 'SAHEL - SAHARA BANK (BSIC)',
    'UBA' = 'UNITED BANK OF AFRICA',
    'BOG' = 'BANK OF GHANA',
    'ACC' = 'ACCESS BANK LTD',
    'CBG' = 'CONSOLIDATED BANK GHANA',
    'FNB' = 'FIRST NATIONAL BANK',
    'APB' = 'ARB APEX BANK LIMITED',
}

export interface VirtualAccount {
    id: string;
    businessId: string;
    currency: Currencies;
    bank_code: string;
    bank_name: string;
    account_name: string;
    account_number: string;
    created_at: string;
    updated_at: string;
    business?: Business;
    stage: 'test' | 'live';
    type: 'PERMANENT' | 'TEMPORARY';
    provider_data: any;
    provider: TrxProviders;
}

export interface SettlementAccount {
    id: string;
    businessId: string;
    currency: Currencies;
    channel: TrxChannels;
    network: string;
    account_name: string;
    account_number: string;
    is_primary: boolean;
    details: any;
    created_at: string;
    updated_at: string;
    business: Business;
}

export const feesAddOrPercentage: any = {
    GHS: {
        'CARD': ['percentage'],
        'DEBIT': ['add', 'percentage'],
        'CREDIT': ['add', 'percentage'],
        'SETTLEMENT': ['add'],
    },
    NGN: {
        'DEBIT': ['add', 'percentage'],
        'CREDIT': ['add', 'percentage'],
        'SETTLEMENT': ['add'],
    },
    UGX: {
        'DEBIT': ['percentage'],
        'CREDIT': ['percentage'],
        'SETTLEMENT': ['add'],
    },
    KES: {
        'DEBIT': ['percentage'],
        'CREDIT': ['add', 'percentage'],
        'SETTLEMENT': ['add'],
    },
    TZS: {
        'DEBIT': ['percentage'],
        'CREDIT': ['add'],
        'SETTLEMENT': ['add'],
    },
    XAF: {
        'DEBIT': ['percentage'],
        'CREDIT': ['percentage'],
        'SETTLEMENT': ['add'],
    },
    XOF: {
        'DEBIT': ['percentage'],
        'CREDIT': ['percentage'],
        'SETTLEMENT': ['add'],
    },
    ZMW: {
        'DEBIT': ['percentage'],
        'CREDIT': ['percentage'],
        'SETTLEMENT': ['add'],
    },
};

export interface Message {
    id: number;
    cost: number;
    type: MessageTypes;
    message: string;
    recipient: string;
    business: Business;
    created_at: string;
}

enum MessageTypes {
    SMS = 'SMS',
    EMAIL = 'EMAIL',
}