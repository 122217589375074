import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types } from 'helpers';
import { utilsApi } from '../api/utils.api';

interface Loading {
    primary: string;
    spinning: boolean;
    secondary: string;
}

interface UtilsState {
    env: string;
    limit: number;
    config: {
        fees: any;
        banks: Record<types.Currencies, any>,
        networks: Record<types.Currencies, any>,
    };
    loading: Loading;
    meCache: string;
    businessCache: string;
    addBusiness: boolean;
    isMobileMenuVisible: boolean;
}

interface UtilsSetUtils {
    key: keyof UtilsState;
    value: any;
}

const initialState: UtilsState = {
    env: 'test',
    limit: 25,
    config: {
        fees: {},
        banks: {} as any,
        networks: {} as any,
    },
    loading: {
        primary: '',
        spinning: false,
        secondary: '',
    },
    meCache: '',
    businessCache: '',
    addBusiness: false,
    isMobileMenuVisible: false,
};

export const utilsSlice = createSlice({
    name: '_utils',
    initialState,
    reducers: {
        _setUtils: (state, action: PayloadAction<UtilsSetUtils>) => {
            const { key, value } = action.payload;
            if (key === 'env' || key === 'meCache' || key === 'businessCache') {
                state[key] = value;
            } else if (key === 'limit') {
                state[key] = +value;
            } else if (key === 'loading') {
                if (!value.spinning) {
                    value['primary'] = '';
                    value['secondary'] = '';
                }
                state[key] = { ...state[key], ...value, };
            } else if (key === 'addBusiness' || key === 'isMobileMenuVisible') {
                state[key] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            utilsApi.endpoints.config.matchFulfilled, (state, action) => {
                state.config = action.payload;
            }
        );
    }
});

export const { _setUtils } = utilsSlice.actions;

export default utilsSlice.reducer;