/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { axius, helpers, types, images, datatables } from 'helpers';
import { Button, Drawer, notification, Spin } from 'antd';
import { Alert, LoadingMin, Status } from 'components';
import ReactJson from 'react-json-view';

interface Props {
    row: types.Transaction;
    open: boolean;
    onClose: () => void;
}
const TransactionDetails = ({ row, open, onClose }: Props) => {

    const [data, setData] = React.useState<types.Transaction>({} as types.Transaction);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        if (row.id) {
            axius.get(`transactions/${row.id}`, { cache: helpers.randCode() }).then(res => {
                res.status === 200 && setData(res.data);
                setLoading(false);
            });
        } else {
            setData({} as types.Transaction);
            setLoading(false);
        }
    }, [row]);

    const changeStatus = (status: types.TrxStatus) => {
        setLoading(true);
        axius.put(`transactions/${data.id}`, { status }).then(res => {
            if (res.status === 200) {
                setData(res.data);
                notification.success({ message: `Transaction marked as ${helpers.ucFirst(status)}` });
            } else {
                notification.error({ ...res });
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={onClose}
                maskClosable={false}
            >
                <Spin spinning={loading} indicator={<LoadingMin />}>
                    {data.id && (
                        <>
                            <div className="flex justify-between items-center mb-8">
                                <div className="text-xl font-semibold">Transaction Details</div>
                                <div
                                    className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                                    onClick={onClose}
                                >
                                    <img src={images.Close} alt="Close" />
                                </div>
                            </div>
                            <div className="border border-gray-200 rounded-lg p-5">
                                <div className="text-gray-500 mb-2">Reference</div>
                                <div className="flex items-center mb-4">
                                    <Alert icon={false} color="warning" className="truncate w-10/12">
                                        {data.id}
                                    </Alert>
                                    <div
                                        className="border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-100 p-2 ml-2"
                                        onClick={() => helpers.copyToClipboard(data.id)}
                                    >
                                        <img src={images.Clipboard} alt="Clipboard" />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 mb-4">
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">Amount</div>
                                        <div className={`font-semibold ${data.type === types.TrxTypes.CREDIT ? 'danger' : 'success'} text-xl`}>
                                            {helpers.format.currency(data.amount, data.currency)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">Fee</div>
                                        <div className="flex items-center">
                                            <div className="font-medium text-lg">{helpers.format.currency((data.fee - +(data.metadata?.smsFee || 0)), data.currency)}</div>
                                            <span className="text-xs text-gray-400 ml-1">({data.bear_fee})</span>
                                        </div>
                                    </div>
                                </div>
                                {+data.metadata?.smsFee > 0 && (
                                    <div className="grid grid-cols-2 mb-8">
                                        <div>
                                            <div className="text-gray-400 text-sm mb-2">SMS Fee</div>
                                            <div className="flex items-center">
                                                <div className="font-medium text-lg">{helpers.format.currency((+(data.metadata.smsFee || 0)), data.currency)}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="border-b mt-4 mb-9" />
                                {data.channel === types.TrxChannels.CRYPTO && (
                                    <>
                                        <div className="grid grid-cols-2 mb-8">
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Crypto Amount</div>
                                                <div className={`font-semibold ${data.type === types.TrxTypes.CREDIT ? 'danger' : 'success'} text-xl`}>
                                                    {helpers.format.currency(data.metadata.cryptoAmount, data.metadata.currency)}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Rate</div>
                                                <div className="font-medium text-lg">1 {data.metadata.currency} ≈ {helpers.format.currency(data.metadata.rate, data.currency)}</div>
                                            </div>
                                        </div>
                                        <div className="border-b mt-4 mb-9" />
                                    </>
                                )}
                                {data.type === types.TrxTypes.SETTLEMENT && (
                                    <>
                                        <div className="grid grid-cols-2 mb-8">
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Expected amount</div>
                                                <div className={`font-semibold text-xl`}>
                                                    {helpers.format.currency(data.metadata.rate_amount, data.metadata.currency_destination)}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Rate</div>
                                                <div className="font-medium text-lg">1 {data.metadata.currency_source} ≈ {helpers.format.currency(data.metadata.rate, data.currency)}</div>
                                            </div>
                                        </div>
                                        <div className="border-b mt-4 mb-9" />
                                    </>
                                )}
                                <div className="grid grid-cols-2 mb-4">
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">Channel</div>
                                        <div className="font-medium text-base">{types.TrxChannelsLabels[data.channel]}</div>
                                    </div>
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">
                                            {data.channel === types.TrxChannels.BANK ? 'Bank' : 'Network'}
                                        </div>
                                        <div className="font-medium text-base">
                                            {datatables.findNetworkOrBank(data)}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">Status</div>
                                        <div><Status status={row.status} label={types.TrxStatusLabels[row.status]} /></div>
                                    </div>
                                    <div>
                                        <div className="text-gray-400 text-sm mb-2">Date</div>
                                        <div className="font-medium text-base">{helpers.format.date(data.created_at)}</div>
                                    </div>
                                    <div className="col-span-2 mt-8">
                                        <div className="text-gray-400 text-sm mb-2">Reason</div>
                                        <div className="font-medium text-base">{data.reason}</div>
                                    </div>
                                    {data.metadata?.rejection_reason && (
                                        <div className="col-span-2 mt-8">
                                            <div className="text-gray-400 text-sm mb-2">Rejection reason</div>
                                            <div className="font-medium text-base">{data.metadata?.rejection_reason}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="text-xl font-semibold my-3">Customer Details</div>

                            <div className="border border-gray-200 rounded-lg p-5">
                                <div className="grid grid-cols-2">
                                    {data.channel === types.TrxChannels.BANK && (
                                        <div className="col-span-2 mb-6">
                                            <div className="text-gray-400 text-sm mb-2">Sender</div>
                                            <div className="font-medium text-base">
                                                {data.metadata?.senderName || data.metadata?.sender_name || 'N/A'}
                                            </div>
                                        </div>
                                    )}
                                    {data.customer?.accountName && (
                                        <div>
                                            <div className="text-gray-400 text-sm mb-2">Name</div>
                                            <div className="font-medium text-base">
                                                {data.customer?.accountName}
                                            </div>
                                        </div>
                                    )}
                                    <div className={`${data.channel === types.TrxChannels.CRYPTO ? 'col-span-2 mt-2' : ''}`}>
                                        <div className="text-gray-400 text-sm mb-2">
                                            {data.channel === types.TrxChannels.CRYPTO ? 'Wallet address' : 'Number'}
                                        </div>
                                        <div className="font-medium text-base">
                                            {helpers.format.phoneNumber(data.customer?.accountNumber || '')}
                                        </div>
                                    </div>
                                    {data.metadata?.settlement_account && (
                                        <div className="mt-2 col-span-2">
                                            {Object.keys(data.metadata.settlement_account.details).map(sad => (
                                                <div><span className="capitalize">{sad.split('_').join(' ')}</span>: {data.metadata.settlement_account.details[sad]}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {data.type !== types.TrxTypes.SETTLEMENT && (
                                <>
                                    <div className="text-xl font-semibold my-3">Provider Details</div>
                                    <div className="border border-gray-200 rounded-lg p-5">
                                        <div className="grid grid-cols-2">
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Provider</div>
                                                <div className="font-medium text-base">
                                                    {data.provider}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-gray-400 text-sm mb-2">Reference</div>
                                                <div className="font-medium text-base">
                                                    {data.reference || 'N/A'}
                                                </div>
                                            </div>
                                            <div className="col-span-2 mt-8">
                                                <div className="text-gray-400 text-sm mb-2">Response</div>
                                                <div className="text-sm courier overflow-auto bg-gray-100 px-3 py-6 rounded-md">
                                                    <ReactJson src={data.provider_init_response || {}} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} style={{ fontSize: 10 }} />
                                                </div>
                                            </div>
                                            <div className="col-span-2 mt-8">
                                                <div className="text-gray-400 text-sm mb-2">Callback</div>
                                                <div className="text-sm courier overflow-auto bg-gray-100 px-3 py-6 rounded-md">
                                                    <ReactJson src={data.provider_hook_response || {}} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} style={{ fontSize: 10 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-xl font-semibold my-3">Callback</div>
                                    <div className="border border-gray-200 rounded-lg p-5">
                                        <div className="grid grid-cols-2">
                                            <div className="col-span-2 mt-8">
                                                <div className="text-sm courier overflow-auto bg-gray-100 px-3 py-6 rounded-md">
                                                    <ReactJson src={data.callback_data || {}} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} style={{ fontSize: 10 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {data.status === types.TrxStatus.INITIATED && !data.reference && (
                                <>
                                    <div className="text-xl font-semibold my-3">Mark as</div>
                                    <div className="border border-gray-200 rounded-lg p-5 flex justify-between gap-6">
                                        <Button type="primary" block danger onClick={() => changeStatus(types.TrxStatus.FAILED)}>
                                            <div className="flex gap-2 items-center justify-center">
                                                <span className="material-symbols-outlined text-[18px]">cancel</span> Failed
                                            </div>
                                        </Button>
                                        <div className="px-6" />
                                        <Button type="primary" block onClick={() => changeStatus(types.TrxStatus.COMPLETED)}>
                                            <div className="flex gap-2 items-center justify-center">
                                                <span className="material-symbols-outlined text-[18px]">check_circle</span> Completed
                                            </div>
                                        </Button>
                                    </div>
                                </>
                            )}
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </>
                    )}
                </Spin>
            </Drawer>
        </React.Fragment>
    );
}

export default TransactionDetails;