import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';


import { PersistGate } from 'redux-persist/integration/react';
import { StoreProvider } from './_Store';
import { persistor, store } from './Store/_Store';

import './index.less';
import './assets/scss/_scss.scss';
import { config } from 'helpers';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#4aa4a5',
    },
    secondary: {
      main: '#045e60',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});

const antdTheme = {
  token: {
    colorPrimary: '#4aa4a5',
    colorSecondary: '#045e60',
    borderRadius: 8,
    fontFamily: '"Inter", sans-serif',
  },
};


Sentry.init({
  dsn: config.sentry_dns,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['https://dash.ogateway.io/', 'https://internal-dash.ogateway.io/'],
    }),
    new Sentry.Replay(),
  ],
  environment: config.env,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ThemeProvider theme={muiTheme}>
          <ConfigProvider theme={antdTheme}>
            <StoreProvider>
              <App />
            </StoreProvider>
          </ConfigProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
